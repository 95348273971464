exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-clases-tsx": () => import("./../../../src/pages/clases.tsx" /* webpackChunkName: "component---src-pages-clases-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../../../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-poses-tsx": () => import("./../../../src/pages/poses.tsx" /* webpackChunkName: "component---src-pages-poses-tsx" */),
  "component---src-pages-yoga-tsx": () => import("./../../../src/pages/yoga.tsx" /* webpackChunkName: "component---src-pages-yoga-tsx" */)
}

